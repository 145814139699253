import React, { useEffect } from "react";
import NavBar from "../../../components/navbar/navbar.component";
import { AddNew } from "../../Partner-Pages/Vehicle/vehicles.styled";
import {
  Table,
  Flex,
  TableWrapper,
  TableContainer,
  SearchInput,
} from "../../../components/Table/table.styled";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import PlaceIcon from '@mui/icons-material/Place';
import Alert_ from "../../../helper/alert/alert";
import {
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Badge,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Heading } from "../../../components/Tags/tags.styled";
import { Close, Key, SearchOutlined } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "../../../app/features/Customer/Order/allOrdersSlice";
import { cancelOrder } from "../../../app/features/Customer/Order/cancelOrderSlice";
import { estimatedPrice } from "../../../app/features/Customer/Order/estimatedPriceSlice";
import convertDate from "../../../helper/dateCoverter";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { suggestAddr } from "../../../app/features/Customer/Order/suggestAddressSlice";
import { addOrder } from "../../../app/features/Customer/Order/addOrderSlice";
import { getAllVehicleTypes } from "../../../app/features/Customer/vehicleTypes/vehicleTypeSlice";
import {vehicleSizes} from "../../../app/features/Partner/vehicles/vehicleExternals/vehicleSizes";
import { InputContainer } from "../../Auth/auth.styled";
import { userObj } from "../../../helper/protectedRoutes";
import { getPlaceId } from "../../../app/features/Customer/Order/placeIdSlice";
import {toast} from 'react-toastify';
import { uploadFiles } from "../../../app/features/Customer/Order/uploadFilesSlice";
import { customerCards } from "../../../app/features/Customer/wallet/customercardsSlice";
import ShopBag from "../../../assets/images/shop-bag-32.png"
import { addPayment } from "../../../app/features/Customer/wallet/addWalletPayment"
import { addPaymentError } from "../../../app/features/Customer/wallet/addWalletPaymentErrorSlice";
import { PaystackButton } from "react-paystack";



const CustomerOrderPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = userObj?.extra.id;
  const currentUser = userObj?.extra
  const userWalletObj = userObj?.extra.wallet;
  const dollarUSLocale = Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});

  const init_estimatePricePayload = {
    customerId: id,
    vehicleTypeId: null,
    vehicleSizeId: null,
    pickup:{},
    destinationPoints:[],
    locationType:1
}

  const [data, setData] = React.useState([]);
  const [estimatePricePayload, setEstimatePricePayload] = React.useState(
    init_estimatePricePayload,
  );
  const [dispatchPayload, setDispatchPayload] = React.useState({
    
  });
  
  
  const [destPointParam, setDestPointParam] = React.useState({})
  const [estTime, setEstTime] = React.useState([])
  const [estimatedPriceResponse, setEstimatedPriceResponse] = React.useState({})
  const [openEditVehicle, setOpenEditVehicle] = React.useState(false);
  const [openViewOrder, setOpenViewOrder] = React.useState(false);
  const [orderDetails, setOrderDetails] = React.useState([]);
  const [openOrderLocationDetails, setOrderLocationDetails] =
    React.useState(false);
  const [openSuggestLocationDetails, setOpenSuggestLocationDetails] =
  React.useState(false);
  const [openOrderVehicleDetails, setOrderVehicleDetails] =
    React.useState(false);
    const [openDestinationDetails, setOpenDestinationDetails] =
    React.useState(false);
    const [openAdditionalDestinationDetails, setOpenAdditionalDestinationDetails] =
    React.useState(false);
  const [orderEstimatePriceModal, setOrderEstimatePriceModal] = React.useState(false);
  const [openPaymentMethod, setOpenPaymentMethod] = React.useState(false);
  const [openWalletPay, setOpenWalletPay] = React.useState(false);
  const [openCardPay, setOpenCardPay] = React.useState(false);
  const [pickup, setpickup] = React.useState([]);
  const [selectedPickup, setSelectedPickup] = React.useState(null);
  const [destination, setDestination] = React.useState([]);
  const [selectedDestination, setSelectedDestination] = React.useState([]);
  const [dropoffinputlength, setdropoffinputlength] = React.useState(0);
  const [pickupinputlength, setpickupinputlength] = React.useState();
  const [vehicleType, setVehicleType] = React.useState([]);
  const [vehicleSize, setVehicleSize] = React.useState([]);
  const [alertToggle, setAlertToggle] = React.useState({
    isVisible: false,
    color: "success",
    message: "",
  });
  const [cashOption, setCashOption] = React.useState("no")
  const [openCardOrderPay, setCardPay] = React.useState(false)
  const [payRef, setReference] = React.useState(null)
  const [payCardDetails, setPayCardDetails] = React.useState(null)
  const [paymentError, setError] = React.useState(null)
  const [amount, setAmount] = React.useState("")
  const [card, setCard] = React.useState({})
  const [isCancel, setIsCancel] = React.useState(false)
  const [query, setQuery] = React.useState("");
  const [images, setImages] = React.useState([])
  const img = (e) =>{
    const file = e.target.files[0];
    const itemImages = [...images];
    console.log('fileImg >>', file)
    if (file) {
      const { name, type, size } = file;
      const fileModel = {
        uri: URL.createObjectURL(file),
        type: type,
        name: name,
        length: size
      }
      console.log('fileImgM >>', fileModel)
      itemImages.push(fileModel);
      console.log("itemImgs :>>", itemImages)
      setImages(itemImages);
      console.log('images :>>', images)
    }
  }
  const [openOrderType, setOpenOrderType] = React.useState(false)

  useEffect(() => {
    document.title = "Order - customer";
    dispatch(getAllOrders()).then((res) => {
      const { payload } = res;
      setData(payload);
    });
    dispatch(getAllVehicleTypes()).then((res) => {
      const { payload, error } = res;
      {
        !error && setVehicleType(payload);
      }
    });
    dispatch(vehicleSizes()).then((res) => {
      const { payload, error } = res;
      {
        !error && setVehicleSize(payload);
      }
    });
  }, []);

  const { isLoading } = useSelector((state) => state.getAllOrders);
  const { isCancelling } = useSelector((state) => state.cancelOrder);
  const { cards, isLoadingCards } = useSelector((state) => state.customercards);
  const { isEstimating } = useSelector((state) => state.estimatedPrice);
  const { isAddingOrder } = useSelector((state) => state.addOrder);
  const isPaying = useSelector((state) => state.addPayment);
  const { isSuggestingAddr } = useSelector((state) => state.suggestAddress);
  const { vTypeIsLoading } = useSelector((state) => state.vehicleTypes);
  const { vehicleSizeIsLoading, vehicleSizeData } = useSelector((state) => state.vehicleSizes);

  // Search Function
  const search = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  
  // estimatePricePayload.destinationPoints.map((formatted_addr, key)=>console.log(formatted_addr.destination))

  // Alert Functions
  const handleAlertOpen = (color_param, message_param) => {
    setAlertToggle({
      ...alertToggle,
      isVisible: true,
      color: color_param,
      message: message_param,
    });
  };

  const handleAlertClose = () => {
    setAlertToggle({
      ...alertToggle,
      isVisible: false,
    });
  };

  const handleCardPay = () => {
    setCardPay(true)
  }
  const closeCardPay = () => {
    setCardPay(false)
  }
  const handleOpenOrderType = () => {
    setOpenOrderType(true)
  }
  const handleCloseOrderType = () => {
    setOpenOrderType(false)
  }
  const submitPayment = (reference) => {
    setReference(reference)
    // setPayCardDetails(authorization)
    console.log('payCardDetails :>>', payRef)
    if (payRef) {
      const body = {
        walletId: userWalletObj.id,
        amount: parseInt(estimatedPriceResponse?.orderPrice?.maxValue),
        reference: payRef,
        paymentDate: new Date(),
        customerCard: {
          customerId: currentUser.id,
          cardNumber: 'Temporary Card - ****',
          cardName: 'authorization.account_name'
        }
      }
      dispatch(addPayment(body)).then(res => {
        const { payload, error } = res;
        setError(error)
        console.log('payment :>>', payload)
        console.log('paymentError >>', error)
        if (!error) {
          dispatch(addOrder(dispatchPayload)).then((res) => {
            const { payload, error } = res;
            if (!error) {
              toast.success("Order successfully created");
              dispatch(getAllOrders()).then((res) => {
                const { payload } = res;
                setData(payload);
              });
              handleCloseWalletPay();
              handleCloseCardPay();
              handleCloseOrderLocation();
            } else {
              toast.error("Error occured while placing your order");
              console.log(error);
            }
          })
        } else {
          toast.error(paymentError.message)
          console.log(error)
        }
      })
    }
  }
  const handlePaymentError = () => {
    const body = {
      walletId: userWalletObj.id,
      amount: parseFloat(estimatedPriceResponse?.orderPrice?.maxValue),
      paymentDate: new Date(),
      createdAt: new Date(),
      createdBy: 'Web'
    }
    dispatch(addPaymentError(body))
  }
  const useCardPayment = () => {
    const body = {
      walletId: userWalletObj.id,
      amount: parseInt(estimatedPriceResponse?.orderPrice?.maxValue),
      paymentDate: new Date(),
      customerCardId: card,
      status: 0
    }
    console.log('cardPayBody >>', body)
    dispatch(addPayment(body)).then((res) => {
      const { payload, error } = res;
      setError(error)
      console.log('paymentError >>', paymentError.message)
      console.log('cardPay :>>', payload)
      if (!error) {
        dispatch(addOrder(dispatchPayload)).then((res) => {
          const { payload, error } = res;
          console.log('orderCardPay :>>', payload)
          if (!error) {
            toast.success("Order successfully created");
            handleCloseWalletPay();
            handleCloseOrderLocation();
          } else {
            toast.error("Error occured while placing your order");
            console.log(error);
          }
        })
      } else {
        toast.error(paymentError.message + ". " + payload)
        console.log(error)
      }
    })
  }
  const publicKey = "pk_test_adeccd8e1f7b4b24716dfe32fa47b73e257b53b8"
  const handlePaystackPayClick = () => {
    handlePaymentError()
    let payConfig = {
      email: currentUser.email,
      amount: amount,
      metadata: {
        name: currentUser.firstName + currentUser.lastName,
        phone: currentUser.phoneNumber
      },
      publicKey,
      onSuccess: ({reference}) => {
        submitPayment(reference)
      },
  
      onClose: () => {
        toast.error('Payment gateway unexpectedly closed')
      }
    }
    console.log('setAmount', amount)
    console.log('payConfig', payConfig)
    let el = document.querySelector(".paystack_botton_")
    return el.click()
  }
  const payConfig = {
    email: currentUser.email,
    amount,
    metadata: {
      name: currentUser.firstName + currentUser.lastName,
      phone: currentUser.phoneNumber
    },
    publicKey,
    onSuccess: ({reference, authorization}) => {
      submitPayment(reference, authorization)
    },

    onClose: () => {
      toast.error('Payment gateway unexpectedly closed')
    }
  }

  const handleCashOption = (e) => {
    setCashOption(e.target.value)
    console.log('cash >>', cashOption)
  }
  
  const openCancel = () => {
    setIsCancel(true)
  }
  const closeCancel = () => {
    setIsCancel(false)
  }
  const cancleOrder = () => {
    dispatch(cancelOrder(orderDetails?.id)).then(res => {
      const { payload, error } = res;
      if (!error) {
        toast.success("Order cancelled successfully");
        handleCloseViewOrder()
        closeCancel()
        dispatch(getAllOrders()).then((res) => {
          const { payload } = res;
          setData(payload);
        });
      } else {
        toast.error(error.message)
        console.log(error)
      }
    })
  }

  // Modal Functions
  const handleOpenViewOrder = (order_details) => {
    setOrderDetails(order_details)
      console.log("ORDER DETAILS", order_details)
    setOpenViewOrder(true);
  };

  const handleCloseViewOrder = () => {
    setOpenViewOrder(false);
  };

  const handleSubmitEditVehicle = () => {
    handleAlertOpen("warning", "Successully edited vehicle");
    setOpenEditVehicle(false);
  };

  const handleCloseEditVehicle = () => {
    setOpenEditVehicle(false);
  };

  // Set Order Location Modal
  const handleOpenOrderLocation = () => {
    setOrderLocationDetails(true);
  };

  const stateCleanUp = () => {
    setEstimatePricePayload(init_estimatePricePayload)
    setSelectedPickup(null)
    setDestination([])
    setpickup([])
    setDestPointParam({})
    setEstTime([])
    setOrderDetails([])
  }

  const handleCloseOrderLocation = () => {
    setOrderLocationDetails(false);
    setOpenOrderType(true)
    stateCleanUp()
  };

  // Set Order Vehicle Modal
  const handleOpenOrderVehicle = () => {
    // handleCloseOrderLocation();
    // setOrderVehicleDetails(true);
  };

  const handleCloseOrderVehicle = () => {
    // handleCloseOrderLocation();
    // setOrderVehicleDetails(false);
  };

  // Set Order Item Modal
  const handleOpenOrderItem = () => {
    // handleCloseOrderVehicle();
    
    let formData = new FormData()
    formData.append("Files", images)
    dispatch(uploadFiles(formData)).then((res) => {
      const { error, payload } = res;
      if(!error) {
        console.log("ImgAPIres >>", payload)
        dispatch(estimatedPrice(estimatePricePayload)).then((res) => {
          const { error, payload } = res;
          if (!error) {
            const { orderPrice, estimatedDistance, estimatedTime, orderDate } =
              payload;
            let est_time = new Date(estimatedTime * 1000)
              .toISOString()
              .slice(11, 19);
            let formatted_time_hh_ss =
              est_time.slice(0, 2) + "h " + est_time.slice(3, 5) + "m";
            setEstTime([...estTime, formatted_time_hh_ss]);
            setEstimatedPriceResponse(payload);
            setAmount(parseInt(orderPrice?.maxValue * 100))
            setDispatchPayload({
              ...estimatePricePayload,
              ["OrderDate"]: orderDate,
              ["orderPrice"]: orderPrice,
              ["estimatedDistance"]: estimatedDistance,
              ["estimatedTime"]: estimatedTime,
              ["DestinationPointString"]: JSON.stringify(
                estimatePricePayload.destinationPoints
              ),
            });
            setOrderEstimatePriceModal(true);
          }
        });
      }
    })
    
  };

  const handleCloseOrderItem = () => {
    // handleCloseOrderVehicle();
    setOrderEstimatePriceModal(false);
  };

  // Set Payment method Modal
  const handleOpenPaymentOptions = () => {
    handleCloseOrderItem();
    setOpenPaymentMethod(true);
  };

  const handleClosePaymentOptions = () => {
    handleCloseOrderItem();
    setOpenPaymentMethod(false);
  };

  // Set Payment method Modal
  const handleOpenWalletPay = () => {
    if(userWalletObj?.currentBalance > estimatedPriceResponse?.orderPrice?.maxValue){
      handleClosePaymentOptions();
      setOpenWalletPay(true);
    }else{
      toast.error('Oops! You do not have enough wallet balance for this order')
    }
    
  };

  const handleCloseWalletPay = () => {
    setOpenPaymentMethod(true);
    setOpenWalletPay(false);
  };

  const handleOpenCardPay = () => {
    handleClosePaymentOptions();
    dispatch(customerCards())
    setOpenCardPay(true);
  };

  const handleCloseCardPay = () => {
    setOpenPaymentMethod(true);
    setOpenCardPay(false);
  };

  const handleCloseSuggestPickLocation = () => {
    setpickup([]);
    setOpenSuggestLocationDetails(false)
  };

  const handleOpenSuggestPickLocation = () => {
    setOpenSuggestLocationDetails(true)
  };

  const submitDestinationDetails = () => {
    dispatch(estimatedPrice(estimatePricePayload)).then((res)=>{
      const {error, payload} = res
      if(!error){
        const {estimatedTime} = payload
        let est_time = new Date(estimatedTime * 1000).toISOString().slice(11, 19);
        let formatted_time_hh_ss = est_time.slice(0,2)+"h "+est_time.slice(3,5)+"m"
        setEstTime([
          ...estTime,
          formatted_time_hh_ss
        ])
        setDestination([]);
    estimatePricePayload.destinationPoints[0] = destPointParam
    setOpenDestinationDetails(false)
    setDestPointParam({})
    setSelectedDestination([])
    if(openAdditionalDestinationDetails){
      setOpenAdditionalDestinationDetails(false)
    }
      }
    })
    
  }

  // console.log("DEST POINT PARAM--->",destPointParam)
  console.log("ESTIMATE PRICE PAYLOAD--->",estimatePricePayload)
  // console.log("DISPATCH PAYLOAD", dispatchPayload)

  const handleCloseDestinationDetails = () => {
    setDestination([]);
    setDestPointParam({})
    setOpenDestinationDetails(false)
  };

  const handleOpenDestinationDetails = () => {
    setOpenDestinationDetails(true)
  };

  const handleCloseAdditionalDestinationDetails = () => {
    setDestination([]);
    setOpenAdditionalDestinationDetails(false)
  };

  const submitAdditionalDestinationDetails = () => {
    dispatch(estimatedPrice(estimatePricePayload)).then((res)=>{
      const {error, payload} = res
      if(!error){
        const {estimatedTime} = payload
        let est_time = new Date(estimatedTime * 1000).toISOString().slice(11, 19);
        let formatted_time_hh_ss = est_time.slice(0,2)+"h "+est_time.slice(3,5)+"m"
        setEstTime([
          ...estTime,
          formatted_time_hh_ss
        ])
        setDestination([]);
    estimatePricePayload.destinationPoints.push(destPointParam)
    setDestPointParam({})
    setSelectedDestination([])
    setOpenAdditionalDestinationDetails(false)
   
      }
    })
  };

  const removeAdditionalDest = (addr) => {
    let a = estimatePricePayload.destinationPoints.filter(item=> {
      if(item?.destination === addr) {
        let index_ = estimatePricePayload.destinationPoints.indexOf(item)
        estimatePricePayload.destinationPoints.splice(index_, 1)
        
      }
    }) 
    setEstimatePricePayload({
      ...estimatePricePayload,
      ['destinationPoints']: estimatePricePayload.destinationPoints
    })
    console.log(estimatePricePayload.destinationPoints)
  }

  const handleOpenAdditionalDestinationDetails = () => {
    setOpenAdditionalDestinationDetails(true)
  };

  

  const { isGettingPlaceId,placeIddata } = useSelector((state) => state.placeId);

  const handlePickupSelect = (pickup_) => {
    // setSelectedPickup(pickup_);
    dispatch(getPlaceId(pickup_?.place_id)).then((res)=>{
      const {payload, error} = res;
      {
        !error &&
        setSelectedPickup(payload?.formatted_address)
        setEstimatePricePayload({
          ...estimatePricePayload,
            'pickup': payload?.formatted_address,
            'pickupLocModel': {
              'longitude':payload?.geometry.location['lng'],
              'latitude':payload?.geometry.location['lat']
            }
          
        })
      }
    })
    
    setpickup([]);
    handleCloseSuggestPickLocation()
  };

  const handleDestinationDetails = (e) => {
    const {name, value} = e.target
    setEstimatePricePayload({
      ...estimatePricePayload,
      [name] : value
    })
  }


  const handleDestinationTextInputs = (e) => {
    const {name, value} = e.target
    setDestPointParam({
      ...destPointParam,
      [name] : value
    })
  }

  const handleAddOrder = () => {
    dispatch(addOrder(dispatchPayload)).then((res)=>{
      const {payload, error} = res
      if(!error){
        toast.success("Order successfully created")
        handleCloseWalletPay()
        handleClosePaymentOptions();
        handleCloseOrderType()
        handleCloseOrderLocation()
      }else{
        toast.error("Failed to place order")
        console.log(error)
      }
    })
  };

  const handleDestinationSelect = (destination_) => {
    dispatch(getPlaceId(destination_?.place_id)).then((res)=>{
      const {payload, error} = res;
      
      {
        !error &&
        setSelectedDestination(payload?.formatted_address)
        setDestPointParam({
          ...destPointParam,
          'destination': payload?.formatted_address,
          'destinationLocModel': {
            'longitude':payload?.geometry.location['lng'],
            'latitude':payload?.geometry.location['lat']
          }
        
        })
        // setEstimatePricePayload({
        //   ...estimatePricePayload,
        //     ["destinationPoints"]:[
        //       ...estimatePricePayload.destinationPoints,
        //       {
        //     'destination': payload?.formatted_address,
        //     'destinationLocModel': {
        //       'longitude':payload?.geometry.location['lng'],
        //       'latitude':payload?.geometry.location['lat']
        //     }}
        //   ]
        // })
      }
    })
    setDestination([]);
    // setSelectedDestination(destination_);
  };

  const handleSuggestPickupAddress = (e) => {
    const { name, value } = e.target;
    setpickupinputlength(value?.length);
    if (value?.length > 2) {
      dispatch(suggestAddr(value)).then((res) => {
        const { error, payload } = res;
        {
          if (!error) {
            setpickup(payload);
          }
        }
      });
    }
  };

  const handleSuggestDestination = (e) => {
    const { name, value } = e.target;
    setdropoffinputlength(value?.length);
    if (value?.length > 2) {
      dispatch(suggestAddr(value)).then((res) => {
        const { error, payload } = res;
        {
          if (!error) {
            setDestination(payload);
          }
        }
      });
    }
  };

  const suggestedAddress = pickup?.map((payload) => {
    return { label: payload.description, place_id: payload.place_id };
  });

  function orderStatus(status) {
    switch (status) {
      case 0:
          return (<span className="badge bg-secondary px-3">Pending</span>);
      case 1:
          return (<span className="badge bg-info px-3">Accepted</span>);
      case 2:
          return (
              <span className="badge bg-warning px-3">Awaiting Pickup</span>
          );
      case 3:
        return (<span className="badge bg-light px-3">Arrived</span>);
      case 4:
        return (<span className="badge bg-light px-3">Awaiting rider code</span>);
      case 5:
          return (<span className="badge bg-light px-3">In Transit</span>);
      case 10:
          return (<span className="badge bg-success px-3">Completed</span>);
      case 11:
          return (<span className="badge bg-danger px-3">Cancelled</span>);
      default:
          return (<span className="badge bg-secondary px-3">Pending</span>);
    }
  }

  return (
    <>
      <NavBar />
      <Alert_
        status={alertToggle.isVisible}
        onClose={handleAlertClose}
        color={alertToggle.color}
      />

      {/* ------------------- Alert --------------------
      <Snackbar open={alertToggle} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
          Successfully Assigned Driver to Vehicle
        </Alert>
      </Snackbar> */}

      <div className="main-content main-content-margin-top">
      
        <div name="Order Type">
          <Dialog
            open={openOrderType}
            onClose={handleCloseOrderType}
          >
            <Box
              style={{ marginTop: "10px" }}
              sx={{
                maxWidth: "auto",
                display: "flex",
              }}
            >
              <Button style={{}} onClick={handleCloseOrderType} color="error">
                <KeyboardBackspaceIcon />
              </Button>
              <DialogTitle style={{ paddingLeft: "0" }}>
                Select Order Type
              </DialogTitle>
            </Box>
            <DialogContent>
              <Box>
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700", marginBottom: "5px" }}
                  variant="outlined"
                  color="error"
                  sx={{ width: "100%" }}
                  onClick={() => handleOpenOrderLocation()}
                >
                 Express (Same day delivery)
                </Button>
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700", marginBottom: "5px" }}
                  variant="outlined"
                  color="error"
                  sx={{ width: "100%" }}
                  onClick={() => navigate("/dashboard/customer/orders/package-schedule")}
                >
                  Scheduled Delivery
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </div>
        {/* Book ride Modal */}
        <div name="Location Node">
          <Dialog
            open={openOrderLocationDetails}
            onClose={handleCloseOrderLocation}
          >
            <DialogTitle>Book ride</DialogTitle>
            <DialogContent>
              {/* <button onClick={()=> console.log(selectedPickup)}>Test</button> */}
              
              <div>
                <Box
                  style={{ marginTop: "10px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    style={{cursor:'none'}}
                    fullWidth
                    label="Pickup location"
                    id="fullWidth"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // value={estimatePricePayload.pickup}
                    value={selectedPickup}
                    onKeyPress={() => handleOpenSuggestPickLocation()}
                    onClick={() => handleOpenSuggestPickLocation()}
                  />
                 
                </Box>

                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >


                  <TextField
                    fullWidth
                    label="Destination"
                    id="fullWidth"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={estimatePricePayload.destinationPoints[0]?.destination}
                    onKeyPress={() => handleOpenDestinationDetails()}
                    onClick={() => handleOpenDestinationDetails()}
                    // onChange={(e) => handleSuggestDestination(e)}
                  />
                  {
                    estTime.length > 0 &&
                  
                  <p style={{fontSize:'small'}}>Est Time: {estTime[0]}</p>
}
                  {/* {dropoffinputlength > 2 && (
                    <nav aria-label="secondary mailbox folders">
                      {isSuggestingAddr & (destination.length > 0) ? (
                        <Flex
                          style={{
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <CircularProgress />
                        </Flex>
                      ) : (
                        <List>
                          {destination.map((row) => {
                            return (
                              <ListItem disablePadding>
                                <ListItemButton
                                  onClick={() => handleDestinationSelect(row)}
                                >
                                  <ListItemText
                                    primary={`${row?.description}`}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>
                      )}
                    </nav>
                  )} */}
                </Box>

        {
          estimatePricePayload.destinationPoints.slice(1).map((formatted_addr, key)=>{
            return(
<Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  
                    <Chip
                    label={formatted_addr?.destination}
                    // onClick={handleClick}
                    onDelete={()=>removeAdditionalDest(formatted_addr?.destination)}
                  />
                  <p style={{fontSize:'small'}}>Est Time: 1h 25m</p>
                  </Box>
                  

          )})
        }

                

                  <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                <Button 
                disabled={(selectedPickup === null || estimatePricePayload.destinationPoints.length === 0) ? true : false}
                onClick={()=>handleOpenAdditionalDestinationDetails()} variant="outlined">Add new destination</Button>
                </Box>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseOrderLocation}
              >
                Close
              </Button>

              {isEstimating ?
              <Button
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "700",
                      border: "none",
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    <CircularProgress />
                  </Button>
:

              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                disabled={(selectedPickup === null || estimatePricePayload.destinationPoints.length === 0) ? true : false}
                onClick={()=>handleOpenOrderItem()}
              >
              
                Next
              </Button>
}
            </DialogActions>
          </Dialog>
        </div>

        <div name="Suggest Pickup Location Node">
          <Dialog
            open={openSuggestLocationDetails}
            onClose={handleCloseSuggestPickLocation}
          >
            <DialogTitle>Enter Pick-up Address</DialogTitle>
            <DialogContent>
              <div>
                <Box
                  style={{ marginTop: "10px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Pickup location"
                    id="fullWidth"
                    onChange={(e) => handleSuggestPickupAddress(e)}
                  />
                  {pickupinputlength > 2 && (
                    <nav aria-label="secondary mailbox folders">
                      {isSuggestingAddr & (pickup.length > 0) ? (
                        <Flex
                          style={{
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <CircularProgress />
                        </Flex>
                      ) : (
                        <List>
                          {pickup.map((row) => {
                            return (
                              <ListItem disablePadding>
                                <ListItemButton
                                  onClick={() => handlePickupSelect(row)}
                                >
                                  <PlaceIcon
                                  style={{marginRight:'10px'}}
                                  />
                                  <ListItemText
                                    primary={`${row?.description}`}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>
                      )}
                    </nav>
                  )}
                </Box>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseSuggestPickLocation}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div name=" Destination Details Node">
          <Dialog
            open={openDestinationDetails}
            onClose={handleCloseDestinationDetails}
          >
            <DialogTitle>Enter destination details</DialogTitle>
            <DialogContent>
              <div>

                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  {
                    selectedDestination.length !== 0 ?
                    <Chip
                    label={selectedDestination}
                    // onClick={handleClick}
                    onDelete={()=> {
                      estimatePricePayload.destinationPoints.pop()
                      setSelectedDestination([])
                    }
                    }
                  />
                  :
                  <TextField
                    fullWidth
                    label="Destination"
                    id="fullWidth"
                    onChange={(e)=> handleSuggestDestination(e)}
                    size="small"
                  />
                  }
                  {dropoffinputlength > 2 && selectedDestination.length === 0 && (
                    <nav aria-label="secondary mailbox folders">
                      {isSuggestingAddr & (destination.length > 0) ? (
                        <Flex
                          style={{
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <CircularProgress />
                        </Flex>
                      ) : (
                        <List>
                          {destination.map((row) => {
                            return (
                              <ListItem disablePadding>
                                <ListItemButton
                                  onClick={() => handleDestinationSelect(row)}
                                >
                                   <PlaceIcon
                                  style={{marginRight:'10px'}}
                                  />
                                  <ListItemText
                                    primary={`${row?.description}`}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>
                      )}
                    </nav>
                  )}
                </Box>
                <FormControl style={{ width: "100%", margin: "20px 0" }} size="small">
                <InputLabel id="demo-simple-select-helper-label">
                  Vehicle type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper fullWidth"
                  value={estimatePricePayload.vehicleTypeId}
                  name="vehicleTypeId"
                  label="Select driver"
                  onChange={(e)=>handleDestinationDetails(e)}
                >
                  {vTypeIsLoading ? (
                    <Flex center>
                      <MenuItem value="">
                        <CircularProgress />
                      </MenuItem>
                    </Flex>
                  ) : (
                    vehicleType?.map((vtypes, Key) => (
                      <MenuItem key={Key} value={vtypes?.id}>
                        {vtypes?.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>

              <FormControl style={{ width: "100%" }} size="small">
                <InputLabel id="demo-simple-select-helper-label">
                  Truck Capacity
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={estimatePricePayload.vehicleSizeId}
                  name="vehicleSizeId"
                  label="Select driver"
                  onChange={(e)=>handleDestinationDetails(e)}
                >
                   {vehicleSizeIsLoading ? (
                    <Flex center>
                      <MenuItem value="">
                        <CircularProgress />
                      </MenuItem>
                    </Flex>
                  ) : (
                    vehicleSize.filter((type) => {
                      return type?.typeId === estimatePricePayload.vehicleTypeId
                    }
                    ).map((vsize, Key) => (
                      <MenuItem key={Key} value={vsize?.id}>
                        {vsize?.name}
                      </MenuItem>
                    ))
                  )}
                 
                </Select>
              </FormControl>

              <Box
                style={{ marginTop: "20px" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  label="Name of Recepient"
                  id="fullWidth"
                  size="small"
                  name="recipientName"
                  onChange={(e)=>handleDestinationTextInputs(e)}
                  // defaultValue={vehicleDetails?.vehicleNum}
                />
              </Box>

              <Box
                style={{ marginTop: "20px" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  label="Phone number of recepient"
                  id="fullWidth"
                  size="small"
                  name="recipientPhone"
                  onChange={(e)=>handleDestinationTextInputs(e)}
                  // defaultValue={vehicleDetails?.vehicleNum}
                />
              </Box>


              <Box
              style={{ marginTop: "20px" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
                
              >
                <TextField
                  fullWidth
                  label="Item weight"
                  id="fullWidth"
                  size="small"
                  name="weight"
                  onChange={(e)=>handleDestinationTextInputs(e)}
                  // defaultValue={vehicleDetails?.vehicleNum}
                />
                
              </Box>

              


              <Box
                style={{ marginTop: "20px" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  label="Item description"
                  name="description"
                  size="small"
                  multiline
                  rows={3}
                  id="fullWidth"
                  onChange={(e)=>handleDestinationTextInputs(e)}
                  // defaultValue={vehicleDetails?.vehicleNum}
                />
              </Box>

              <br/>
              <InputContainer>
                <p>Item Image</p>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input accept="image/*" type="file" name="Picture1File" onChange={(e) => img(e)} />
                </IconButton>
              </InputContainer>
              <FormLabel id="demo-controlled-radio-buttons-group">Would you like the rider to collect any fee upon delivery?</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={cashOption}
                  row
                  onChange={(e) => handleCashOption(e)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  { cashOption === "yes" ?
                  <TextField
                    fullWidth
                    label="Amount"
                    name="cashonDelivery"
                    size="small"
                    type="number"
                    id="fullWidth"
                    onChange={(e) => handleDestinationTextInputs(e)}
                    // defaultValue={vehicleDetails?.vehicleNum}
                  /> : 
                  <> </>
                  }
                </Box>

              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseDestinationDetails}
              >
                Close
              </Button>
{isEstimating ?
              <Button
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "700",
                      border: "none",
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    <CircularProgress />
                  </Button>
:
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                onClick={()=>submitDestinationDetails()}
              >
                Submit
              </Button>
}
            </DialogActions>
          </Dialog>
        </div>

        <div name="Additional Destination Details Node">
          <Dialog
            open={openAdditionalDestinationDetails}
            onClose={()=>handleCloseAdditionalDestinationDetails()}
          >
            <DialogTitle>Enter destination details</DialogTitle>
            <DialogContent>
              <div>

                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  {
                    selectedDestination.length !== 0 ?
                    <Chip
                    label={selectedDestination}
                    // onClick={handleClick}
                    onDelete={()=> {
                      estimatePricePayload.destinationPoints.pop()
                      setSelectedDestination([])
                    }
                    }
                  />
                  :
                  <TextField
                    fullWidth
                    label="Destination"
                    id="fullWidth"
                    onChange={(e)=> handleSuggestDestination(e)}
                    size="small"
                  />
                  }
                  {dropoffinputlength > 2 && selectedDestination.length === 0 && (
                    <nav aria-label="secondary mailbox folders">
                      {isSuggestingAddr & (destination.length > 0) ? (
                        <Flex
                          style={{
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <CircularProgress />
                        </Flex>
                      ) : (
                        <List>
                          {destination.map((row) => {
                            return (
                              <ListItem disablePadding>
                                <ListItemButton
                                  onClick={() => handleDestinationSelect(row)}
                                >
                                  <PlaceIcon
                                  style={{marginRight:'10px'}}
                                  />
                                  <ListItemText
                                    primary={`${row?.description}`}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>
                      )}
                    </nav>
                  )}
                </Box>
              
              <Box
                style={{ marginTop: "20px" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  label="Name of Recepient"
                  id="fullWidth"
                  size="small"
                  name="recipientName"
                  onChange={(e)=>handleDestinationTextInputs(e)}
                  // defaultValue={vehicleDetails?.vehicleNum}
                />
              </Box>

              <Box
                style={{ marginTop: "20px" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  label="Phone number of recepient"
                  id="fullWidth"
                  size="small"
                  name="recipientPhone"
                  onChange={(e)=>handleDestinationTextInputs(e)}
                  // defaultValue={vehicleDetails?.vehicleNum}
                />
              </Box>


              <Box
              style={{ marginTop: "20px" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
                
              >
                <TextField
                  fullWidth
                  label="Item weight"
                  id="fullWidth"
                  size="small"
                  name="weight"
                  onChange={(e)=>handleDestinationTextInputs(e)}
                  // defaultValue={vehicleDetails?.vehicleNum}
                />
                
              </Box>

              


              <Box
                style={{ marginTop: "20px" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  label="Item description"
                  name="description"
                  size="small"
                  multiline
                  rows={3}
                  id="fullWidth"
                  onChange={(e)=>handleDestinationTextInputs(e)}
                  // defaultValue={vehicleDetails?.vehicleNum}
                />
              </Box>

              <br/>
              <InputContainer>
                <p>Item Image</p>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input accept="image/*" type="file" name="Picture1File" onChange={(e) => img(e)} />
                </IconButton>
              </InputContainer>
              <FormLabel id="demo-controlled-radio-buttons-group">Would you like the rider to collect any fee upon delivery?</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={cashOption}
                  row
                  onChange={(e) => handleCashOption(e)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  { cashOption === "yes" ?
                  <TextField
                    fullWidth
                    label="Amount"
                    name="cashonDelivery"
                    size="small"
                    type="number"
                    id="fullWidth"
                    onChange={(e) => handleDestinationTextInputs(e)}
                    // defaultValue={vehicleDetails?.vehicleNum}
                  /> : 
                  <> </>
                  }
                </Box>

              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={()=>handleCloseAdditionalDestinationDetails()}
              >
                Close
              </Button>

              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                onClick={submitAdditionalDestinationDetails}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        

        <div name="Vehicle Type Node">
          <Dialog
            open={openOrderVehicleDetails}
            onClose={handleCloseOrderVehicle}
          >
            <DialogTitle>Vehicle Details</DialogTitle>
            <DialogContent>
              <FormControl style={{ width: "100%", margin: "20px 0" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Vehicle type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper fullWidth"
                  //   value={assignDriverProperty.driver}
                  name="driver"
                  label="Select driver"
                  //   onChange={handleAssignDriverChange}
                >
                  {vTypeIsLoading ? (
                    <Flex center>
                      <MenuItem value="">
                        <CircularProgress />
                      </MenuItem>
                    </Flex>
                  ) : (
                    vehicleType?.map((vtypes, Key) => (
                      <MenuItem key={Key} value={vtypes?.id}>
                        {vtypes?.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>

              <FormControl style={{ width: "100%", margin: "20px 0" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Truck Capacity
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  //   value={assignDriverProperty.driver}
                  name="driver"
                  label="Select driver"
                  //   onChange={handleAssignDriverChange}
                >
                   {vehicleSizeIsLoading ? (
                    <Flex center>
                      <MenuItem value="">
                        <CircularProgress />
                      </MenuItem>
                    </Flex>
                  ) : (
                    vehicleSize?.map((vsize, Key) => (
                      <MenuItem key={Key} value={vsize?.id}>
                        {vsize?.name}
                      </MenuItem>
                    ))
                  )}
                 
                </Select>
              </FormControl>

              <Box
                style={{ marginTop: "20px" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  label="Name of Recepient"
                  id="fullWidth"
                  // defaultValue={vehicleDetails?.vehicleNum}
                />
              </Box>

              <Box
                style={{ marginTop: "20px" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  label="Phone number of recepient"
                  id="fullWidth"
                  // defaultValue={vehicleDetails?.vehicleNum}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              {/* <Button style={{textTransform:'capitalize'}} color="error" onClick={handleCloseViewVehicle}>Cancel</Button> */}
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseOrderVehicle}
              >
                Close
              </Button>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                onClick={handleOpenOrderItem}
              >
                Next
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div name="Item Node">
          <Dialog open={orderEstimatePriceModal} onClose={handleCloseOrderItem}>
          <Box
              style={{ marginTop: "10px", marginBottom: "-45px" }}
              sx={{
                maxWidth: "auto",
              }}
            >
              <Button
                style={{ float: "right" }}
                onClick={handleCloseOrderItem}
                color="error"
              >
                <Close />
              </Button>
            </Box>
            <DialogTitle>Estimate</DialogTitle>
            <DialogContent>
<p>Estimated Price for your order is</p>
{estimatedPriceResponse?.orderPrice
&&
<p>&#8358;{dollarUSLocale.format(estimatedPriceResponse?.orderPrice?.minValue)} - &#8358;{dollarUSLocale.format(estimatedPriceResponse?.orderPrice?.maxValue)}</p>
}


{/* <p>{estimatedPriceResponse?.}</p> */}
              <br/>
              
            </DialogContent>
            <DialogActions>
              {/* <Button style={{textTransform:'capitalize'}} color="error" onClick={handleCloseViewVehicle}>Cancel</Button> */}
             
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                onClick={handleOpenPaymentOptions}
              >
                Proceed to payment
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div name="Payment method Node">
          <Dialog open={openPaymentMethod} onClose={handleClosePaymentOptions}>
            <Box
              style={{ marginTop: "10px", marginBottom: "-40px" }}
              sx={{
                maxWidth: "auto",
              }}
            >
              <Button
                style={{ float: "right" }}
                onClick={handleClosePaymentOptions}
                color="error"
              >
                <Close />
              </Button>
            </Box>
            <DialogTitle style={{ textAlign: "center" }}>
              Select Payment Option
            </DialogTitle>
            <DialogContent>
              <Box
                style={{ marginTop: "10px" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                {/* <Flex style={{justifyContent:'space-evenly'}}> */}
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="contained"
                  color="primary"
                  sx={{ width: "100%" }}
                  onClick={()=>handleOpenWalletPay()}
                >
                  Wallet
                </Button>

                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    marginTop: "10px",
                  }}
                  variant="contained"
                  color="primary"
                  sx={{ width: "100%" }}
                  onClick={() => handleAddOrder()}
                >
                  Cash
                </Button>

                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    marginTop: "10px",
                  }}
                  variant="contained"
                  color="primary"
                  sx={{ width: "100%" }}
                  onClick={()=>handleOpenCardPay()}
                >
                  Card
                </Button>

                {/* </Flex> */}
              </Box>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </div>

        <div name="Select Card">
          <Dialog open={openCardPay} onClose={handleCloseCardPay}>
            <DialogTitle>Select prefered card below</DialogTitle>

            {
              isLoadingCards &&
              <Button
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "700",
                      border: "none",
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    <CircularProgress />
                  </Button>
}
{ !isLoadingCards &&
            <DialogContent>
              <Box
                style={{ marginTop: "10px" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                {cards.length > 0 ? (
                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Payment option
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    //   value={assignDriverProperty.driver}
                    name="driver"
                    label="Select driver"
                    onChange={(e) => setCard(e.target.value)}
                    //   onChange={handleAssignDriverChange}
                  >
                    {
                      cards.map((card, key)=> {
                        return(
                            <MenuItem key={key} value={card.id}>
                              { "**** **** **** " + card.cardNumber.slice(-4) + " -- " + card.bank } 
                            </MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
                ) : (
                  "No cards currently associated with this account"
                )}
              </Box>

              <Button
              variant="outlined"
              onClick={() => handlePaystackPayClick()}
              >Add new card</Button>
              <div style={{display:'none'}}>
              <PaystackButton className="paystack_botton_" id="paystack_botton_" {...payConfig}/>
              </div>
            </DialogContent>
            }
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseCardPay}
              >
                Close
              </Button>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                disabled={card === null}
                onClick={handleCardPay}
              >
                Pay now
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div name="Card Pay">
          <Dialog open={openCardOrderPay} onClose={closeCardPay}>
            <DialogTitle>Order Summary</DialogTitle>
            <DialogContent>
              {estimatedPriceResponse?.orderPrice && (
                <div className="">
                  <span style={{ fontSize: "14px" }}>Estimated Price</span>
                  <p style={{ fontSize: "17px", fontWeight: 700 }}>
                    &#8358;
                    {dollarUSLocale.format(
                      estimatedPriceResponse?.orderPrice?.minValue
                    )}{" "}
                    - &#8358;
                    {dollarUSLocale.format(
                      estimatedPriceResponse?.orderPrice?.maxValue
                    )}
                  </p>
                </div>
              )}
              <div className="">
                <span style={{ fontSize: "14px" }}>Payment method</span>
                <p style={{ fontSize: "17px", fontWeight: 700 }}>
                  Card Payment
                </p>
              </div>
              <div className="">
                <span style={{ fontSize: "14px" }}>Pickup</span>
                <p style={{ fontSize: "17px", fontWeight: 700 }}>
                  {selectedPickup}
                </p>
              </div>
              <span style={{ fontSize: "14px" }}>Destination Points</span>
              <div className="px-3 py-2" style={{ backgroundColor: "#E8E8E8"}}>
                {estimatePricePayload.destinationPoints.map(
                  (formatted_addr, key) => {
                    return (
                      <>
                        <div className="mb-2">
                          <span style={{ fontSize: "15px", color: "blue" }} key={key}>Destination Point {key + 1}</span>
                          <p className="mb-0" style={{ fontSize: "14px", fontWeight: 600 }}>
                          {formatted_addr?.destination}
                          </p>
                        </div>
                      </>
                    );
                  }
                )}
              </div>
              {/* <p>Confirm Payment with your wallet balance</p> */}
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={closeCardPay}
              >
                No
              </Button>
              {isAddingOrder || isPaying ? (
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    border: "none",
                  }}
                  variant="outlined"
                  color="primary"
                >
                  <CircularProgress />
                </Button>
              ) : (
                // <Button
                //   style={{ textTransform: "capitalize", fontWeight: "700" }}
                //   variant="contained"
                //   color="primary"
                //   onClick={() => {initializePayment(onSuccess, onClose)}}
                // >
                //   Confirm
                // </Button>
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="contained"
                  color="primary"
                  onClick={useCardPayment}
                >
                  Confirm
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>

        <div name="Wallet Pay">
          <Dialog open={openWalletPay} onClose={handleCloseWalletPay}>
            <DialogTitle>Order Summary</DialogTitle>
            <DialogContent>
              {estimatedPriceResponse?.orderPrice && (
                <div className="">
                  <span style={{ fontSize: "14px" }}>Estimated Price</span>
                  <p style={{ fontSize: "17px", fontWeight: 700 }}>
                    &#8358;
                    {dollarUSLocale.format(
                      estimatedPriceResponse?.orderPrice?.minValue
                    )}{" "}
                    - &#8358;
                    {dollarUSLocale.format(
                      estimatedPriceResponse?.orderPrice?.maxValue
                    )}
                  </p>
                </div>
              )}
              <div className="">
                <span style={{ fontSize: "14px" }}>Payment method</span>
                <p style={{ fontSize: "17px", fontWeight: 700 }}>
                  Wallet Payment
                </p>
              </div>
              <div className="">
                <span style={{ fontSize: "14px" }}>Pickup</span>
                <p style={{ fontSize: "17px", fontWeight: 700 }}>
                  {selectedPickup}
                </p>
              </div>
              <span style={{ fontSize: "14px" }}>Destination Points</span>
              <div className="px-3 py-2" style={{ backgroundColor: "#E8E8E8"}}>
                {estimatePricePayload.destinationPoints.map(
                  (formatted_addr, key) => {
                    return (
                      <>
                        <div className="mb-2">
                          <span style={{ fontSize: "15px", color: "blue" }} key={key}>Destination Point {key + 1}</span>
                          <p className="mb-0" style={{ fontSize: "14px", fontWeight: 600 }}>
                          {formatted_addr?.destination}
                          </p>
                        </div>
                      </>
                    );
                  }
                )}
              </div>
              {/* <p>Confirm Payment with your wallet balance</p> */}
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseWalletPay}
              >
                No
              </Button>
              {isAddingOrder ?
              <Button
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "700",
                      border: "none",
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    <CircularProgress />
                  </Button>
                  :
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                onClick={()=> handleAddOrder()}
              >
                Confirm
              </Button>
}
            </DialogActions>
          </Dialog>
        </div>

        {/* Order Modal */}
        <div name="View Order">
          <Dialog open={openViewOrder} onClose={handleCloseViewOrder}>
            <DialogTitle>Order details</DialogTitle>
            <DialogContent>
              <div className="d-flex">
                <div class="flex-shrink-0">
                  <img src={ShopBag} alt="shop bag" height="40" width="40" className="img-fluid" />
                </div>
                <div className="flex-grow-1 ms-3 d-flex">
                  <div className="">
                    <span style={{ color: "blue" }}>{orderDetails?.orderNo}</span>
                    <p style={{ fontWeight: 700 }}>
                      { orderDetails?.vehicleType?.name + ' (' + orderDetails?.vehicleSize?.name + ')' }
                    </p>
                  </div>
                  <div className="ms-auto">
                    { orderStatus(orderDetails?.status) }
                  </div>
                </div>
              </div>
              <div className="">
                <div className="">
                  <span style={{ fontSize: "14px" }}>Rider</span>
                  <p className="mb-0 pb-1" style={{ fontSize: "17px", fontWeight: 700}}>
                    { orderDetails?.orderRider
                      ? orderDetails?.orderRider?.driver?.rider?.firstName + " " + orderDetails?.orderRider?.driver?.rider?.lastName
                      : "N/A" }
                  </p>
                  <span style={{ fontSize: "14px" }}>Rider Code</span>
                  <p style={{ fontSize: "17px", fontWeight: 700 }}>
                    { orderDetails?.orderRider ? orderDetails?.orderRider?.riderCode : "N/A" }
                  </p>
                </div>
                { orderDetails?.orderPrice && 
                (<div className="">
                  <span style={{ fontSize: "14px" }}>Delivery fee</span>
                  <p style={{ fontSize: "17px", fontWeight: 700 }}>
                    { orderDetails?.orderPrice?.actualValue > 0
                      ? '₦' + orderDetails?.orderPrice?.actualValue?.toFixed(2)?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                      : '₦' + orderDetails?.orderPrice?.minValue?.toFixed(2)?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + " - ₦" + orderDetails?.orderPrice?.maxValue?.toFixed(2)?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }
                  </p>
                </div>)
                }
                <div className="">
                  <span style={{ fontSize: "14px" }}>Pickup location</span>
                  <p style={{ fontSize: "17px", fontWeight: 700 }}>
                    { orderDetails?.pickup }
                  </p>
                </div>
                <div className="px-3 py-2" style={{ backgroundColor: "#E8E8E8"}}>
                  {orderDetails?.destinationPoints?.map((dest, key)=>{
                    return (
                      <>
                        <div className="">
                          <span style={{ fontSize: "15px", color: "blue" }} key={key}>Delivery Point {key + 1}</span>
                          <p className="mb-0" style={{ fontSize: "14px", fontWeight: 600 }}>
                            { dest?.destination }
                          </p>
                        </div>
                      </>
                    )
                    })
                  }
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              {/* <Button style={{textTransform:'capitalize'}} color="error" onClick={handleCloseViewVehicle}>Cancel</Button> */}
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseViewOrder}
              >
                Close
              </Button>
              { orderDetails?.destinationPoints?.length === 0 &&
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="primary"
                onClick={() => navigate(`/dashboard/customer/order-tracking/${orderDetails?.orderNo}`)}
              >
                Track
              </Button>
              }
              { orderDetails?.status !== 10 && orderDetails?.status !== 11
                ? <Button
                    style={{ textTransform: "capitalize", fontWeight: "700" }}
                    variant="contained"
                    color="error"
                    onClick={openCancel}
                  >
                    Cancel order
                  </Button>
                : <></>
              }
            </DialogActions>
          </Dialog>
        </div>

        {/* Cancel Order Modal */}
        <div name="Cancel Order">
          <Dialog open={isCancel} onClose={closeCancel}>
            <DialogContent>
              <div className="">
                <p style={{ fontSize: "18px", fontWeight: 600, textAlign: 'center' }}>
                  Are you sure you want to cancel this order?
                </p>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="primary"
                onClick={closeCancel}
              >
                No
              </Button>
              { isCancelling
                ? <Button
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "700",
                      border: "none",
                    }}
                    variant="outlined"
                    color="error"
                  >
                    <CircularProgress />
                  </Button>
                : <Button
                    style={{ textTransform: "capitalize", fontWeight: "700" }}
                    variant="contained"
                    color="error"
                    onClick={()=> cancleOrder()}
                  >
                    Cancel
                  </Button>
              }
            </DialogActions>
          </Dialog>
        </div>

    

        <TableContainer>
          <Heading size="1.8em">Orders</Heading>
          <AddNew>
            <Button
              variant="outlined"
              onClick={() => handleOpenOrderType()}
              startIcon={<AddIcon />}
            >
              Create new
            </Button>
          </AddNew>
          <Stack direction="row">
            <p
              style={{
                fontSize: "1.3em",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              Search
            </p>
            <SearchInput type="text" onChange={search} />
          </Stack>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Order Number</th>
                  {/* <th>Est. Distance</th> */}
                  <th>Pick-up</th>

                  {/* <th>Est. Delivery Date</th> */}
                  <th>Est. Value</th>
                  <th>Status</th>
                </tr>
              </thead>
              {
                !isLoading &&
              
              <tbody>
                {data
                  ?.filter((order) =>
                    order.orderNo.toLowerCase().includes(query.toLowerCase())
                  )
                  ?.map((row_, Key) => {
                    return (
                      <tr>
                        <td style={{width:'10px'}}>{Key + 1}</td>
                        <td>{row_.orderNo}</td>
                        
                        <td style={{width:'300px'}}>{row_.pickup}</td>
                       

                        <td style={{width:'200px'}}>{row_.orderPrice === null ? "N/A" : row_?.status !== 10 ? "₦" + dollarUSLocale.format(row_?.orderPrice?.minValue) + " - ₦" + dollarUSLocale.format(row_?.orderPrice?.maxValue) : "₦" + dollarUSLocale.format(row_?.orderPrice?.actualValue) }</td>
                        <td style={{width:'180px'}}>
                          {row_?.status === 0 && <span style={{color:'#f9c851'}}>pending</span>}
                          {row_?.status === 1 && <span style={{color:'#188ae2'}}>accepted</span>}
                          {row_?.status === 2 && <span style={{color:'#ff6666'}}>awaiting pickup</span>}
                          {row_?.status === 3 && <span style={{color:'#666699'}}>arrived</span>}
                          {row_?.status === 4 && <span style={{color:'#f9c851'}}>awaiting rider code</span>}
                          {row_?.status === 5 && <span style={{color:'#bf4080'}}>In Transit</span>}
                          {row_?.status === 10 && <span style={{color:'#10c469'}}>Completed</span>}
                          {row_?.status === 11 && <span style={{color:'#ff5b5b'}}>cancelled</span>}
                        </td>
                        <td style={{width:'180px'}}>
                          <Button
                            onClick={()=>handleOpenViewOrder(row_)}
                            style={{
                              textTransform: "capitalize",
                              fontSize: "small",
                            }}
                            variant="outlined"
                          >
                            Order details
                          </Button>
                        </td>
                        {/* <td><button onClick={()=> alert(row_.vehicleNum)}>view</button></td> */}
                      </tr>
                    );
                  })}
              </tbody>
}
            </Table>
            {isLoading && <Button style={{width:'100%', marginTop:'20px'}}><CircularProgress/></Button>}
          </TableWrapper>
        </TableContainer>
        
      </div>
    </>
  );
};

export default CustomerOrderPage;
