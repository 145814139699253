import { combineReducers, configureStore, applyMiddleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
// Reducers Import below
import customerRegisterReducer from "./features/Customer/registerSlice";
import partnerRegisterReducer from "./features/Partner/registerSlice";
import customerLoginReducer from "./features/Customer/loginSlice";
import forgotPasswordReducer from "./features/forgotPasswordSlice";
import resetPasswordReducer from "./features/resetPasswordSlice";
import customerValidateEmailReducer from "./features/Customer/validateEmailSlice";
import customerValidatePhoneReducer from "./features/Customer/validateSMSTokenSlice";
import customerGenerateSMSTokenReducer from "./features/Customer/generateSMSToken";
import getAllVehiclesReducer from "./features/Partner/vehicles/getAllVehicles"
import vehicleBrandsReducer from "./features/Partner/vehicles/vehicleExternals/vehicleBrands";
import vehicleCategoriesReducer from "./features/Partner/vehicles/vehicleExternals/vehicleCategories";
import vehicleModelsReducer from "./features/Partner/vehicles/vehicleExternals/vehicleModels";
import vehicleSizesReducer from "./features/Partner/vehicles/vehicleExternals/vehicleSizes";
import vehicleTypesReducer from "./features/Partner/vehicles/vehicleExternals/vehicleTypes";
import addVehicleReducer from "./features/Partner/vehicles/addVehicle";
import deleteVehicleReducer from "./features/Partner/vehicles/deleteVehicleSlice";
import editVehicleReducer from "./features/Partner/vehicles/editVehicleSlice";
import addRiderReducer from "./features/Partner/rider/addRider";
import logoutReducer from "./features/logoutSlice";
import getAllOrdersReducer  from "./features/Customer/Order/allOrdersSlice";
import getAllRidersReducer from "./features/Partner/rider/getAllRiders";
import updateRiderReducer from "./features/Partner/rider/updateRiderSlice";
import deleteRiderReducer from "./features/Partner/rider/deleteRiderSlice";
import changePasswordReducer from "./features/changePasswordSlice";
import assignRiderReducer from "./features/Partner/rider/assignRider";
import suggestAddressReducer from "./features/Customer/Order/suggestAddressSlice";
import placeIdReducer from "./features/Customer/Order/placeIdSlice";
import financeReducer from "./features/Partner/Finance/financeSlice";
import estimatedPriceReducer from "./features/Customer/Order/estimatedPriceSlice";
import calculatePriceReducer from "./features/Customer/Order/calculatePaymentSlice";
import getCitiesReducer from "./features/Customer/getCitiesSlice";
import getTownsReducer from "./features/Customer/getTownsSlice";
import addOrderReducer from "./features/Customer/Order/addOrderSlice";
import uploadFilesReducer from "./features/Customer/Order/uploadFilesSlice";
import getOrderReducer from "./features/Customer/Order/getOrderSlice";
import cancelOrderReducer from "./features/Customer/Order/cancelOrderSlice";
import walletpaymentsReducer from "./features/Customer/wallet/walletpaymentsSlice";
import createordermodalReducer from "./features/createOrderModal/createordermodalSlice";
import createPackageScheduleReducer from "./features/createOrderModal/packageScheduleSlice"
import customercardsReducer from "./features/Customer/wallet/customercardsSlice";
import getAllWalletsReducer from "./features/Customer/wallet/allWalletsSlice";
import getWalletReducer from "./features/Customer/wallet/getWalletSlice";
import addCardReducer from "./features/Customer/wallet/addCustomerCardSlice";
import addPaymentReducer from "./features/Customer/wallet/addWalletPayment";
import addPaymentErrorReducer from "./features/Customer/wallet/addWalletPaymentErrorSlice";
import getPickupsReducer from "./features/Customer/Pickup/getPickupsSlice";
import bulkOrderDownloadReducer from "./features/Customer/Order/bulkOrderTemplateSlice";
import addPickupReducer from "./features/Customer/Pickup/addPickupSlice"
import generateRcsReducer from "./features/Customer/Dss/generateRcsSlice"
import getRcsReducer from "./features/Customer/Dss/getAllRcsSlice"
import getRcsDetailReducer from "./features/Customer/Dss/getRcsDetailSlice"
import getDailyShipmentsReducer from "./features/Customer/Dss/getDailyShipmentsSlice"
import importShipmentReducer from "./features/Customer/Dss/importShipmentSlice"
import confirmRcsReducer from "./features/Customer/Dss/confirmRcsSlice";
import updateRcsReducer from "./features/Customer/Dss/updateRcsSlice"
import vehicleDelete, { vehicleReducer } from "./features/Partner/vehicles/vehicle.reducer"
import getDriversReducer from "./features/Customer/Dss/getDriversSlice";
import trackOrderReducer from "./features/Customer/Order/trackingOrderSlice";
import getCodReportReducer from "./features/Customer/Order/getCodReportSlice";


const persistConfig = {
    key:'root',
    storage,
    whitelist:['getAllOrders']
}

const rootReducer = combineReducers({
    customerRegister: customerRegisterReducer,
    partnerRegister: partnerRegisterReducer,
    customerLogin: customerLoginReducer,
    customerValidateEmail: customerValidateEmailReducer,
    customerValidatePhone: customerValidatePhoneReducer,
    customerGenerateSMSToken: customerGenerateSMSTokenReducer,
    forgotPassword:forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    getAllVehicles: getAllVehiclesReducer,
    vehicleBrands : vehicleBrandsReducer,
    vehicleCategories:vehicleCategoriesReducer,
    vehicleModels:vehicleModelsReducer,
    vehicleSizes:vehicleSizesReducer,
    vehicleTypes:vehicleTypesReducer,
    addVehicle:addVehicleReducer,
    deleteVehicle:deleteVehicleReducer,
    vehicleDelete:vehicleReducer,
    editVehicle:editVehicleReducer,
    addRider:addRiderReducer,
    logOut:logoutReducer,
    getAllOrders:getAllOrdersReducer,
    getAllRiders:getAllRidersReducer,
    updateRider:updateRiderReducer,
    deleteRider:deleteRiderReducer,
    changePassword:changePasswordReducer,
    assignRider:assignRiderReducer,
    suggestAddress:suggestAddressReducer,
    placeId:placeIdReducer,
    finance:financeReducer,
    estimatedPrice: estimatedPriceReducer,
    calculatedPrice: calculatePriceReducer,
    addOrder:addOrderReducer,
    uploadFiles:uploadFilesReducer,
    getOrder:getOrderReducer,
    cancelOrder:cancelOrderReducer,
    addPayment:addPaymentReducer,
    addPaymentError: addPaymentErrorReducer,
    walletpayments:walletpaymentsReducer,
    createordermodal:createordermodalReducer,
    createPackageSchedule: createPackageScheduleReducer,
    customercards: customercardsReducer,
    getAllWallets: getAllWalletsReducer,
    getWallet: getWalletReducer,
    addCard: addCardReducer,
    cities: getCitiesReducer,
    towns: getTownsReducer,
    getPickups: getPickupsReducer,
    bulkOrder: bulkOrderDownloadReducer,
    addPickup: addPickupReducer,
    generateRcs: generateRcsReducer,
    getAllRcs: getRcsReducer,
    getRcsDetails: getRcsDetailReducer,
    getShipments: getDailyShipmentsReducer,
    importShipment: importShipmentReducer,
    confirmRcs: confirmRcsReducer,
    updateRcs: updateRcsReducer,
    getDrivers: getDriversReducer,
    trackingOrder: trackOrderReducer,
    getReport: getCodReportReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer )

export const store = configureStore({
    reducer: persistedReducer,
    middleware:(getDefaultMiddleware) => {
        return getDefaultMiddleware();
     }
  })

export const persistor = persistStore(store)