import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress
} from "@mui/material";
import { AddNew } from "../../Partner-Pages/Vehicle/vehicles.styled";
import AddIcon from "@mui/icons-material/Add";
import {
  Table,
  TableWrapper,
  TableContainer
} from "../../../components/Table/table.styled";
import Checkbox from '@mui/material/Checkbox';
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import NavBar from "../../../components/navbar/navbar.component";
import { Heading } from "../../../components/Tags/tags.styled";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toast } from "react-toastify";
import { userObj } from "../../../helper/protectedRoutes";
import { getCities } from "../../../app/features/Customer/getCitiesSlice";
import { getDailyShipments } from "../../../app/features/Customer/Dss/getDailyShipmentsSlice";
import { getDrivers } from "../../../app/features/Customer/Dss/getDriversSlice";
import { bulkOrderDownload } from "../../../app/features/Customer/Order/bulkOrderTemplateSlice";
import { importShipments } from "../../../app/features/Customer/Dss/importShipmentSlice";
import { generateRcs } from "../../../app/features/Customer/Dss/generateRcsSlice";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DailyShipmentsPage = () => {
  const dispatch = useDispatch();
  const user = userObj?.extra;
  console.log("user >>", user);

  const init_payload = {
    CustomerId: user.id,
    OriginId: null,
    AccountNumber: user.id.toString(),
    DateReceived: new Date(),
    File: null,
  };

  const rsc_init_payload = {
    originId: null,
    driverId: null
  };

  const navigate = useNavigate();

  const [shipments, setShipments] = React.useState([]);
  const [pickedShipments, setPickedShipments] = React.useState([])
  const [cities, setCities] = React.useState([]);
  const [drivers, setDrivers] = React.useState([]);
  const [payload, setPayload] = React.useState(init_payload);
  const [rscPayload, setRscPayload] = React.useState(rsc_init_payload);
  const [value, setValue] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [openDownload, setOpenDownload] = React.useState(false);
  const [openBulk, setOpenBulk] = React.useState(false);
  const [openRcs, setOpenRcs] = React.useState(false);

  const date = new Date().toISOString();
  const { isLoading } = useSelector((state) => state.getShipments);
  const { isImporting } = useSelector((state) => state.importShipment);
  const { downloading } = useSelector((state) => state.bulkOrder);
  const { isAdding } = useSelector((state) => state.generateRcs);

  useEffect(() => {
    document.title = "Daily shipments - Customer";
    dispatch(getDailyShipments({ id: user.id, start: date, end: date })).then(
      (res) => {
        const { payload } = res;
        const newShip = payload?.filter(s => s.status === 0);
        setShipments(newShip);
        const picked = payload?.filter(s => s.status !== 0);
        console.log("New shipments", shipments);
        console.log('picked', picked);
        setPickedShipments(picked);
      }
    );
    dispatch(getCities()).then((res) => {
      const { payload } = res;
      setCities(payload);
    });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRowSelection = (rowData) => {
    // const selectedIndex = selected.indexOf(id);
    // let newselected = [];

    // if (selectedIndex === -1) {
    //   newselected = [...selected, id];
    // } else {
    //   newselected = [...selected.slice(0, selectedIndex), ...selected.slice(selectedIndex + 1)];
    // }

    // setSelected(newselected);
    // setSelectAll(newselected.length === shipments.length);

    const isSelected = selected.some((row) => row.id === rowData.id);

    if (isSelected) {
      setSelected(selected.filter((row) => row.id !== rowData.id));
    } else {
      setSelected([...selected, rowData]);
    }
  };

  const handleSelectAllRows = () => {
    // const allRowsSelected = !selectAll;
    // const newselected = allRowsSelected ? shipments.map((row) => row.id) : [];

    // setSelected(newselected);
    // setSelectAll(allRowsSelected);

    if (selected.length === shipments.length) {
      setSelected([]);
    } else {
      setSelected([...shipments]);
    }
  };

  const handleOpenDownload = () => {
    setOpenDownload(true);
  };
  const handleCloseDownload = () => {
    setOpenDownload(false);
  };

  const handleOpenBulk = () => {
    setOpenBulk(true);
    setOpenDownload(false);
  };
  const handleCloseBulk = () => {
    setOpenBulk(false);
  };

  const handleOpenRcs = () => {
    dispatch(getDrivers()).then(res => {
      setDrivers(res.payload);
    })
    setOpenRcs(true)
  }
  const handleCloseRcs = () => {
    setOpenRcs(false)
  }

  const handleCityChange = (e) => {
    const { name, value } = e.target;
    console.log("City >>", name, value);
    setPayload({
      ...payload,
      [name]: value,
    });
    // const city = cities.find((c) => c.id === value);
    // setCity(city);
    // console.log("cityID", city);
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    console.log("file", file);
    setPayload({
      ...payload,
      File: file,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRscPayload({
      ...rscPayload,
      [name]: value
    });
  };

  const handleDownload = () => {
    dispatch(bulkOrderDownload()).then((res) => {
      const { payload, error } = res;
      if (!error) {
        let url = window.URL.createObjectURL(payload);
        let a = document.createElement("a");
        a.href = url;
        a.download = "Order-Template.xlsx";
        a.click();
      } else {
        toast(error);
      }
    });
  };

  const handleImport = () => {
    dispatch(importShipments({ id: user.id, data: payload })).then((res) => {
      const { error, payload } = res;
      if (!error) {
        toast.success("Order Request submitted successfully");
        handleCloseBulk();
        dispatch(
          getDailyShipments({ id: user.id, start: date, end: date })
        ).then((res) => {
          const { payload } = res;
          const newShip = payload?.filter(s => s.status === 0);
          setShipments(newShip);
          const picked = payload?.filter(s => s.status === 1);
          console.log("New shipments", shipments);
          console.log('picked', picked);
          setPickedShipments(picked);
        });
      } else {
        toast.error(payload);
      }
    });
  };

  const handleGenerateRcs = () => {
    const body = {
        customerId: user.id,
        date: new Date(),
        driverId: rscPayload.driverId,
        originId: rscPayload.originId,
        shipmentIds: selected
    }
    dispatch(generateRcs(body)).then((res) => {
      const { error } = res;
      if (!error) {
        toast.success("RCS generated successfully");
        navigate("/dashboard/customer/rcs");
      }
    })
  }

  console.log('Testing', shipments)

  return (
    <>
      <NavBar />
      <div className="main-content main-content-margin-top">
        <div name="Template Download">
          <Dialog
            open={openDownload}
            onClose={handleCloseDownload}
          >
            <Box
              style={{ marginTop: "10px" }}
              sx={{
                maxWidth: "auto",
                display: "flex",
              }}
            >
              <Button style={{}} onClick={handleCloseDownload} color="error">
                <KeyboardBackspaceIcon />
              </Button>
              <DialogTitle style={{ paddingLeft: "0" }}>
                Bulk Order Request
              </DialogTitle>
            </Box>
            <DialogContent>
              <Box
              >
                <div className="">
                  <p
                  ><strong>-</strong> Click the button below to download the file template;</p>
                  <p
                  ><strong>-</strong> Ensure to foloow the format as given in the template;</p>
                  <p
                  ><strong>-</strong> For City, Town, and Payment Type, their corresponding number can be found in the worksheets named after them.</p>
                </div>
                <div className="">
                  <Button
                    style={{ textTransform: "capitalize", fontWeight: "700", width: "100%" }}
                    variant="outlined"
                    color="error"
                    onClick={handleDownload}
                    disabled={downloading}
                  >
                    { downloading ? 'Please wait...' : 'Download' }
                  </Button>
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleOpenBulk}
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div name="Bulk Request">
          <Dialog open={openBulk} onClose={handleOpenBulk}>
            <Box
              style={{ marginTop: "10px", marginBottom: "10px" }}
              sx={{
                maxWidth: "auto",
                display: "flex",
              }}
            >
              <Button style={{}} onClick={handleCloseBulk} color="error">
                <KeyboardBackspaceIcon />
              </Button>
              <DialogTitle style={{ paddingLeft: "0" }}>
                Bulk Order Request
              </DialogTitle>
            </Box>
            <DialogContent>
              <Box>
                <div className="">
                  <FormControl fullWidth sx={{ m: 1 }} size="small">
                    <InputLabel id="demo-select-small-label">Origin</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="OriginId"
                      value={payload.OriginId}
                      label="Origin"
                      onChange={(e) => handleCityChange(e)}
                    >
                      <MenuItem value={null}>
                        <em>-- Select --</em>
                      </MenuItem>
                      {cities?.map((c, key) => (
                        <MenuItem value={c.id} key={key}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                    <InputLabel id="demo-select-small-label">Upload File (Excel only)</InputLabel>
                  <FormControl fullWidth sx={{ m: 1 }} size="small">
                    <input className="" type="file" onChange={handleFile} />
                  </FormControl>
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              {isImporting ? (
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    border: "none",
                  }}
                  variant="outlined"
                  color="error"
                >
                  <CircularProgress />
                </Button>
              ) : (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="outlined"
                  color="error"
                  onClick={handleImport}
                >
                  Create
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
        <div name="RCS Generate">
          <Dialog open={openRcs} onClose={handleOpenRcs}>
            <Box
              style={{ marginTop: "10px", marginBottom: "10px" }}
              sx={{
                maxWidth: "auto",
                display: "flex",
              }}
            >
              <Button style={{}} onClick={handleCloseRcs} color="error">
                <KeyboardBackspaceIcon />
              </Button>
              <DialogTitle style={{ paddingLeft: "0" }}>
                Generate RCS
              </DialogTitle>
            </Box>
            <DialogContent>
              <Box>
                <div className="">
                  <FormControl fullWidth sx={{ m: 1 }} size="small">
                    <InputLabel id="demo-select-small-label">Origin</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="originId"
                      value={rscPayload.originId}
                      label="Origin"
                      onChange={(e) => handleInputChange(e)}
                    >
                      <MenuItem value={null}>
                        <em>-- Select --</em>
                      </MenuItem>
                      {cities?.map((c, key) => (
                        <MenuItem value={c.id} key={key}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} size="small">
                    <InputLabel id="demo-select-small-label">Select Driver</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="driverId"
                      value={rscPayload.driverId}
                      label="Driver"
                      onChange={(e) => handleInputChange(e)}
                    >
                      <MenuItem value={null}>
                        <em>-- Select --</em>
                      </MenuItem>
                      {drivers?.map((c, key) => (
                        <MenuItem value={c.id} key={key}>
                          {c.firstName + " " + c.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              {isImporting ? (
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    border: "none",
                  }}
                  variant="outlined"
                  color="error"
                >
                  <CircularProgress />
                </Button>
              ) : (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="outlined"
                  color="error"
                  onClick={handleGenerateRcs}
                >
                  Create
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
        <TableContainer>
          <Heading size="1.8em">Daily Shipments</Heading>
          <AddNew>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleOpenDownload}
            >
              Create new
            </Button>
          </AddNew>
          {/* <Stack direction="row">
            <p
              style={{
                fontSize: "1.3em",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              Search
            </p>
            <SearchInput type="text" onChange={search} />
          </Stack> */}
          <TableWrapper>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="New" {...a11yProps(0)} />
                  <Tab label="Picked" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <AddNew>
                <Button
                variant="outlined"
                onClick={handleOpenRcs}
                disabled={selected.length > 0 ? false : true}
                >
                Create RCS
                </Button>
                </AddNew>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <Checkbox
                        checked={shipments?.length > 0 && selected?.length === shipments?.length}
                        onChange={handleSelectAllRows}
                        inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </th>
                      <th>Order Number</th>
                      <th>Origin</th>
                      <th>Destination</th>
                      <th>Content</th>
                      <th>Weight</th>
                      <th>Quantity</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  {!isLoading && (
                    <tbody>
                      {shipments
                        // .filter((s) =>
                        //   s.waybillNumber?.toLowerCase().includes(query?.toLowerCase())
                        // )
                        ?.map((row_, Key) => {
                          return (
                            <tr>
                                <th>
                                    <Checkbox
                                    checked={selected.some((selectedRow) => selectedRow.id === row_.id)}
                                    onChange={() => handleRowSelection(row_)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </th>
                              <td>{row_.waybillNumber}</td>

                              <td style={{ width: "300px" }}>{row_.origin.name}</td>
                              <td style={{ width: "300px" }}>{row_.city.name}</td>
                              <td style={{ width: "300px" }}>{row_.contentDescription}</td>
                              <td style={{ width: "300px" }}>{row_.weight + "kg"}</td>
                              <td style={{ width: "300px" }}>{row_.unit}</td>

                              <td style={{ width: "180px" }}>
                                {row_?.status === 0 && (
                                  <span style={{ color: "#f9c851" }}>
                                    pending
                                  </span>
                                )}
                                {row_?.status === 1 && (
                                  <span style={{ color: "#188ae2" }}>
                                    accepted
                                  </span>
                                )}
                                {row_?.status === 2 && (
                                  <span style={{ color: "#ff6666" }}>
                                    awaiting pickup
                                  </span>
                                )}
                                {row_?.status === 3 && (
                                  <span style={{ color: "#666699" }}>
                                    arrived
                                  </span>
                                )}
                                {row_?.status === 4 && (
                                  <span style={{ color: "#f9c851" }}>
                                    awaiting rider code
                                  </span>
                                )}
                                {row_?.status === 5 && (
                                  <span style={{ color: "#bf4080" }}>
                                    In Transit
                                  </span>
                                )}
                                {row_?.status === 10 && (
                                  <span style={{ color: "#10c469" }}>
                                    Completed
                                  </span>
                                )}
                                {row_?.status === 11 && (
                                  <span style={{ color: "#ff5b5b" }}>
                                    cancelled
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  )}
                </Table>
                {isLoading && (
                    <Button style={{ width: "100%", marginTop: "20px" }}>
                        <CircularProgress />
                    </Button>
                    )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Table>
                  <thead>
                    <tr>
                      <th>Order Number</th>
                      <th>Origin</th>
                      <th>Destination</th>
                      <th>Content</th>
                      <th>Weight</th>
                      <th>Quantity</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  {!isLoading && (
                    <tbody>
                      {pickedShipments
                        // .filter((s) =>
                        //   s.waybillNumber?.toLowerCase().includes(query?.toLowerCase())
                        // )
                        ?.map((row_, Key) => {
                          return (
                            <tr>
                              <td>{row_.waybillNumber}</td>

                              <td style={{ width: "300px" }}>{row_.origin.name}</td>
                              <td style={{ width: "300px" }}>{row_.city.name}</td>
                              <td style={{ width: "300px" }}>{row_.contentDescription}</td>
                              <td style={{ width: "300px" }}>{row_.weight + "kg"}</td>
                              <td style={{ width: "300px" }}>{row_.unit}</td>

                              <td style={{ width: "180px" }}>
                                {row_?.status === 0 && (
                                  <span style={{ color: "#f9c851" }}>
                                    pending
                                  </span>
                                )}
                                {row_?.status === 1 && (
                                  <span style={{ color: "#188ae2" }}>
                                    accepted
                                  </span>
                                )}
                                {row_?.status === 2 && (
                                  <span style={{ color: "#ff6666" }}>
                                    awaiting pickup
                                  </span>
                                )}
                                {row_?.status === 3 && (
                                  <span style={{ color: "#666699" }}>
                                    arrived
                                  </span>
                                )}
                                {row_?.status === 4 && (
                                  <span style={{ color: "#f9c851" }}>
                                    awaiting rider code
                                  </span>
                                )}
                                {row_?.status === 5 && (
                                  <span style={{ color: "#bf4080" }}>
                                    In Transit
                                  </span>
                                )}
                                {row_?.status === 10 && (
                                  <span style={{ color: "#10c469" }}>
                                    Completed
                                  </span>
                                )}
                                {row_?.status === 11 && (
                                  <span style={{ color: "#ff5b5b" }}>
                                    cancelled
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  )}
                </Table>
              </CustomTabPanel>
            </Box>
          </TableWrapper>
        </TableContainer>
      </div>
    </>
  );
};

export default DailyShipmentsPage;
