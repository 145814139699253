import React from "react";
import { PhotoCameraOutlined } from "@mui/icons-material";
import NavBar from "../../../components/navbar/navbar.component";
import {
  ProfileWrapper,
  ProfileLinkContents,
  ProfilePhotoContainer,
  ProfilePhotoWrapper,
  ProfilePhotoImg,
  ProfileForm,
  UploadButton,
  ProfileButton,
  EditWrapper
} from "./profile.styled";
import profilePhoto from "../../../assets/images/user.jpg";
import { FormControl, TextField, Button } from "@mui/material";

import ProfileSideBar from "../../../components/ProfileSideBar/profilesidebar.component";
import { useEffect, useState } from "react";
import { Edit } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';


const EditProfile = () => {
  const [data, setData] = useState();
  const userObj = JSON.parse(localStorage.getItem("user"));
  console.log("user :>>", userObj)
  const userRole = userObj?.role[0];

  const [readOnly, setReadOnly] = useState(true)

  const editState = false

  let button;

  if (readOnly === false) {
    button = <ProfileButton>Submit</ProfileButton>
  } else {}
  
  const navigate = useNavigate()


  return (
    <>
      <NavBar />

      <div className="main-content main-content-margin-top">
        <ProfileWrapper>
          <ProfileSideBar />
          <ProfileLinkContents>
            <p style={{ fontSize: "1.5em", margin: "8px 0" }}>
            <i onClick={() => navigate(-1)}>
            <ArrowBackIcon /></i>  Profile</p>
            <hr />

              <EditWrapper>
              <Button onClick={() => setReadOnly((o) => !o)} variant="outlined">
              <Edit style={{ color: "#b23934" }} />{" "}
              </Button>
              
              </EditWrapper>

            <ProfilePhotoContainer>
              <ProfilePhotoWrapper>
                <ProfilePhotoImg src={profilePhoto} />
                <UploadButton>
                  <PhotoCameraOutlined />
                </UploadButton>
              </ProfilePhotoWrapper>
              <div>
                <p>{userObj.extra.companyName}</p>
              </div>
            </ProfilePhotoContainer>
            {userRole.toLowerCase() === "partner" && (
              <ProfileForm action="">
                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Company Name"
                    defaultValue={userObj.extra.companyName}
                    id="fullWidth"
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </FormControl>

                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="CAC Number"
                    defaultValue={userObj.extra.rcNumber}
                    id="fullWidth"
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </FormControl>

                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Contact Name"
                    defaultValue={userObj.extra.contactName}
                    id="fullWidth"
                    InputProps={{
                      readOnly: readOnly
                    }}
                  />
                </FormControl>

                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Company Address"
                    defaultValue={userObj.extra.address}
                    id="fullWidth"
                    InputProps={{
                      readOnly: readOnly
                    }}
                  />
                </FormControl>

                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Company Email"
                    defaultValue={userObj.user.email}
                    id="fullWidth"
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </FormControl>

                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Contact Phone Number"
                    defaultValue={userObj.user.phoneNumber}
                    id="fullWidth"
                    InputProps={{
                      readOnly: readOnly
                    }}
                  />
                </FormControl>
                {
                  readOnly === false
                  ? <ProfileButton>Submit</ProfileButton>
                  : null
                }
              </ProfileForm>
            )}
            {userRole.toLowerCase() === "customer" && (
              <ProfileForm action="">
                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Full name"
                    defaultValue={userObj.user.fullName}
                    id="fullWidth"
                    InputProps={{
                      readOnly: readOnly
                    }}
                  />
                </FormControl>


                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Company Address"
                    defaultValue={userObj.extra.address}
                    id="fullWidth"
                    InputProps={{
                      readOnly: readOnly
                    }}
                  />
                </FormControl>

                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Email"
                    defaultValue={userObj.user.email}
                    id="fullWidth"
                    InputProps={{
                      readOnly: readOnly
                    }}
                  />
                </FormControl>

                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Contact Phone Number"
                    defaultValue={userObj.extra.phoneNumber}
                    id="fullWidth"
                    InputProps={{
                      readOnly: readOnly
                    }}
                  />
                </FormControl>
                {
                  readOnly === false
                  ? <ProfileButton>Submit</ProfileButton>
                  : null
                }
              </ProfileForm>
            )}
          </ProfileLinkContents>
        </ProfileWrapper>
      </div>
    </>
  );
};

export default EditProfile;
