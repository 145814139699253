import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    data:[],
    isLoading:null,
    status:null,
}

const token = userObj?.token

export const walletPayments = createAsyncThunk(
    'customer/walletPayments',
    async (_, {rejectWithValue}) => {
        
        try{
            const response = await axios.get("Wallets/WalletPayments",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const walletPaymentsSlice = createSlice({
    name: 'walletPayments',
    initialState,
    reducers: {},
    extraReducers:{
        [walletPayments.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [walletPayments.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [walletPayments.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})

export default walletPaymentsSlice.reducer;