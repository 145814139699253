import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  Stack,
  FormControlLabel,
} from "@mui/material";
import {
    Table,
    Flex,
    TableWrapper,
    TableContainer,
    SearchInput,
} from "../../../components/Table/table.styled";
import TextField from "@mui/material/TextField";
import NavBar from "../../../components/navbar/navbar.component";
import { Container } from "./orders.styled";
import { Heading } from "../../../components/Tags/tags.styled";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { userObj } from "../../../helper/protectedRoutes";
import { getCodReport } from "../../../app/features/Customer/Order/getCodReportSlice";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";

const CODReportPage = () => {
  const dispatch = useDispatch();
  const user = userObj?.extra;
  console.log("user >>", user);

  const [name, setName] = React.useState("");
  const [startDate, setStartDate] = 
    React.useState("2024-01-01");
  const [endDate, setEndDate] = 
    React.useState(new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'));
  const [data, setData] = React.useState([]);
  const [query, setQuery] = React.useState("");

  const { isLoading } = useSelector((state) => state.getReport);

  const searchValues = {
    name: user.firstName,
    start: startDate,
    end: endDate
  }

  useEffect(() => {
    document.title = "Cash on Delivery Report - Customer";
    dispatch(getCodReport(searchValues)).then((res) => {
      const { payload, error } = res;
      if (!error) {
        setData(payload)
      } else {
        toast.error(payload)
      }
    })
  }, []);

  const search = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  return (
    <>
      <NavBar />
      <div className="main-content main-content-margin-top">
        <TableContainer>
          <Heading size="1.8rem">Cash on Delivery Report</Heading>
          <div className="row mb-4">
            <div className="form-group col-sm-4 mb-3">
                <label className="form-label">Start Date:</label>
                <input
                className="form-control"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                />
            </div>
            <div className="form-group col-sm-4 mb-3">
                <label className="form-label">End Date:</label>
                <input
                className="form-control"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                />
            </div>
            <div className="col-sm-4 mt-sm-4 mb-3">
                <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="primary"
                >Submit</Button>
            </div>
          </div>
          <Stack direction="row">
            <p
              style={{
                fontSize: "1.3em",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              Search
            </p>
            <SearchInput type="text" onChange={search} />
          </Stack>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Order Date</th>
                  <th>Rider</th>
                  <th>Transaction Amount</th>
                  <th>Cash on Delivery</th>
                </tr>
              </thead>
              { !isLoading &&
                <tbody>
                  {
                    data
                    //   .filter((o) => o.orderNo.toLowerCase().inlcudes(query.toLowerCase()))
                      ?.map((cod, key) => {
                        return (
                          <tr>
                            <td>{ cod.orderNo }</td>
                            <td>
                              { cod.orderDate }
                            </td>
                            <td>{ cod.orderRider?.driver?.rider?.firstName } {" "} { cod.orderRider?.driver?.rider?.lastName }</td>
                            <td>
                              { `${cod.orderPrice.actualValue.toLocaleString('ng-EG')}` }
                            </td>
                            <td>
                              { `${cod.cashonDelivery.toLocaleString('ng-EG')}` }
                            </td>
                          </tr>
                        )
                      })
                  }
                </tbody>
              }
            </Table>
            {isLoading && <Button style={{width:'100%', marginTop:'20px'}}><CircularProgress/></Button>}
          </TableWrapper>
        </TableContainer>
      </div>
    </>
  );
};

export default CODReportPage;
