import { Button, IconButton } from "@mui/material";
import styled from "styled-components";

export const ProfileWrapper = styled.div`
    width: 100%;
    display:flex;
    box-sizing:border-box;
    @media screen and (max-width: 768px){
        flex-direction:column;
        width: 95%;
        margin: auto;
    }
`




export const ProfileLinkContents = styled.div`
width: 100%;
flex:2;
background: #f7f9ff;
box-shadow:0 0 5px #80808075;
padding: 25px 25px 60px 25px;
border-radius: 10px;
margin-bottom:20px;
`

export const ProfilePhotoContainer = styled.div`
width: 100%;
display: flex;
flex-direction:column;
align-items:center;
justify-content: center;
margin-top: 20px;
margin-bottom: 20px;

p{
    margin-top: 10px;
    font-size: 1.8em;
}
`
export const EditWrapper = styled.div`
text-align: end;
padding-top:20px;
`

export const ProfilePhotoWrapper = styled.div`
width: 100px;
height: 100px;
border-radius: 50%;
border: 2px solid #607d8b;
padding: 5px;
position:relative;
`
export const ProfilePhotoImg = styled.img`
width: 100%;
height: 100%;
border-radius: 10px;
border-radius: 50%;
`

export const UploadButton = styled.div`
position:absolute;
bottom:-3px;
right:-5px;
color: grey;
svg{
    background:grey;
    padding:5px;
    border-radius:50%;
    color:white;
    font-size:2.2em;
}
`

export const ProfileForm = styled.form`
width: 100%;
`

export const ProfileButton = styled(Button)`
width: 100%;
background: #b23934 !important;
font-weight:bold !important;
padding:10px !important;
color: white !important;
`
